import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import HeroPortfolioBlogPost from '../components/Hero/HeroPortfolioBlogPost';
import RichTextPost from '../components/RichTextPost';
import LayoutTemplate from './LayoutTemplate';

export default function PortfolioTemplate({ data, location }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);
  return (
    <LayoutTemplate showNav>
      <Seo
        title={data.portfolio.seoTitle || data.portfolio.portfolioTitle}
        description={data.portfolio.cardSummaryText?.cardSummaryText}
        keywords={data.portfolio.seoKeywords}
        location={location}
        pathname={location.pathname}
        image={`https:${data.portfolio.cardImage?.file.url}`}
      />
      <HeroPortfolioBlogPost
        image={data.portfolio.heroImage}
        title={data.portfolio.portfolioTitle}
        subtitle={data.portfolio.subtitle}
      />
      <div className="section-container gray">
        <div className="portfolio-client-details">
          <div className="client">
            <h3>Client</h3>
            <p>
              {data.portfolio.client?.map((client) => client.name).join(', ')}
            </p>
          </div>
          {data.portfolio.category ? (
            <div className="services">
              <h3>Services</h3>
              <p>
                {data.portfolio.category
                  .join(', ')
                  .replace(/, ([^,]*)$/, ', and $1')}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {data.portfolio.content ? (
        <RichTextPost content={data.portfolio.content} />
      ) : null}
    </LayoutTemplate>
  );
}

export const query = graphql`
  query getPortfolio($slug: String!) {
    portfolio: contentfulPortfolioPiece(slug: { eq: $slug }) {
      portfolioTitle
      subtitle
      heroImage {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
      }
      cardImage {
        file {
          url
        }
      }
      cardSummaryText {
        cardSummaryText
      }
      category
      seoTitle
      seoKeywords
      client {
        name
      }
      content {
        raw
        references {
          ...sections
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            assetDescription: description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
      category
    }
  }
`;
